<head>
  <link
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    rel="stylesheet"
  />
  <!-- TODO : replace the scss content by the file hosted on s3-->
</head>

<div class="bg-custom">
  <div class="container">
    <div class="row full-height justify-content-center">
      <div
        class="col-12 text-center align-self-center py-5"
        style="max-height: 100vh"
      >
        <div class="pb-5 pt-5 pt-sm-2 text-center">
          <div class="d-inline-block switch-button">
            <input
              class="switch-button-checkbox checkbox"
              [(ngModel)]="isChecked"
              id="reg-log"
              name="reg-log"
              type="checkbox"
            />
            <label class="switch-button-label" for=""
              ><span class="switch-button-label-span">Privé</span></label
            >
          </div>

          <div class="card-3d-wrap mx-auto">
            <div
              class="card-3d-wrapper"
              [style.transform]="
                isChecked ? 'rotateY(180deg)' : 'rotateY(0deg)'
              "
            >
              <div
                class="card-front primary-template-request-link"
                (click)="openRequestsModal('private-presentation')"
              >
                <div
                  [ngStyle]="{ display: isChecked ? 'none' : 'block' }"
                  class="card-body"
                  style="height: 92%"
                >
                  <div class="text-center pl-3 pr-3">
                    <a target="_blank">
                      <img
                        src="assets/img/logo/logo_color.png"
                        class="client-logo mt-3"
                      />
                    </a>
                    <h2 class="mb-3 pb-3 mt-4">
                      Des fichiers sont partagés avec vous ?
                    </h2>
                    <form class="mb-4">
                      <div class="form-group">
                        <input
                          type="email"
                          id="email"
                          placeholder="Enter your email"
                          name="email"
                          class="form-style"
                          placeholder="Votre email"
                          id="logemail"
                          autocomplete="off"
                        />
                        <i
                          class="fa-solid fa-envelope input-icon uil uil-at"
                        ></i>
                      </div>
                      <button type="submit" class="btn mt-2">Valider</button>
                    </form>
                  </div>
                </div>
                <div
                  class="bg-transparent text-right mr-2"
                  style="font-size: medium"
                >
                  <small class="font-weight-bold mb-0 mr-1 text-sm"
                    >*proudly made by</small
                  >
                  <a href="https://pirog.io" target="_blank">
                    <img
                      src="assets/img/logo/logo_color.png"
                      style="height: 20px"
                    />
                  </a>
                </div>
              </div>
              <div
                class="card-back primary-template-request-link"
                (click)="openRequestsModal('public-presentation')"
              >
                <div
                  [ngStyle]="{ display: isChecked ? 'block' : 'none' }"
                  class="card-body"
                  style="height: 92%"
                >
                  <h3>Aucun fichier publique pour le moment.</h3>
                </div>

                <div
                  class="bg-transparent text-right mr-2"
                  style="font-size: medium"
                >
                  <small class="font-weight-bold mb-0 mr-1 text-sm"
                    >*proudly made by</small
                  >
                  <a href="https://pirog.io" target="_blank">
                    <img
                      src="assets/img/logo/logo_color.png"
                      style="height: 20px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 text-center social-network social-circle template-request-link"
          (click)="openRequestsModal('social-medias')"
        >
          <a class="btn-social btn-facebook"
            ><i class="fa-brands fa-facebook-f fa-lg"></i
          ></a>
          <a class="btn-social btn-linkedin"
            ><i class="fa-brands fa-linkedin-in fa-lg"></i
          ></a>
          <a class="btn-social btn-twitter"
            ><i class="fa-brands fa-twitter fa-lg"></i
          ></a>
          <a class="btn-social btn-instagram"
            ><i class="fa-brands fa-instagram fa-lg"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #requestsModal>
  <app-template-requests-edit
    class="w-100"
    [appTemplateRequests]="getTemplateRequests(selectedSubCategory)"
    [subCategory]="'req.background' | translate"
    [application]="application"
    [size]="'md'"
    (templateRequestsChange)="handleTemplateRequestsChange($event)"
  >
  </app-template-requests-edit>
</ng-template>
