import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {UpdateAccountRequest} from "src/app/models/update-account-request.model";
import {User} from "src/app/models/user.model";
import {SeoService} from "src/app/services/seo.service";
import {UserService} from "src/app/services/user.service";
import {LanguageService} from "src/app/services/language.service";
import {logout, updateUser, updateUserSuccess} from "src/app/store/auth/auth.actions";
import {selectUser} from "src/app/store/auth/auth.selectors";
import {environment} from "src/environments/environment";
import swal from "sweetalert2";
import {Actions, ofType} from "@ngrx/effects";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { ToastService } from "src/app/services/toaster.service";

@Component({
  selector: "app-account",
  templateUrl: "account.component.html",
  styleUrls: ["account.component.scss"]
})
export class AccountComponent implements OnInit, OnDestroy {
  user: User;

  userFormGroup: FormGroup = new FormGroup({
    phone: new FormControl(''),
    isUpdatingPassword: new FormControl<boolean>(false, [Validators.required]),
    oldPassword: new FormControl<string>('', [Validators.required]),
    newPassword: new FormControl<string>('', [Validators.required, Validators.minLength(8)]),
    language: new FormControl<'fr' | 'en'>('fr', [Validators.required])
  });

  subscription: Subscription = new Subscription();
  stripeUrl: string;

  showNewPassword = false;
  showOldPassword = false;

  constructor(private store: Store,
              private userService: UserService,
              private toastService: ToastService,
              private translateService: TranslateService,
              private languageService: LanguageService,
              private seoService: SeoService,
              private actions$: Actions) {
    this.stripeUrl = environment.stripePortalUrl;
    this.actions$.pipe(ofType(updateUserSuccess), takeUntilDestroyed()).subscribe(() => {
      this.languageService.setLanguage(this.userFormGroup.value.language);
      this.toastService.success(this.translateService.instant('toastr.success_profile_updated'));
    });
  }

  ngOnInit() {
    this.seoService.setSeoTags({
      titleKey: 'seo.account.title',
      descriptionKey: 'seo.account.description',
      noindex: true
    });
    this.initForm();
    this.loadUserAndFillForm();
  }

  private initForm() {
    this.userFormGroup.controls.oldPassword.disable();
    this.userFormGroup.controls.newPassword.disable();

    this.userFormGroup.controls.isUpdatingPassword.valueChanges.subscribe((value) => {
      if (value) {
        this.userFormGroup.controls.oldPassword.enable();
        this.userFormGroup.controls.newPassword.enable();
      } else {
        this.userFormGroup.controls.newPassword.disable();
        this.userFormGroup.controls.oldPassword.disable();
      }
    });
  }

  private loadUserAndFillForm() {
    this.subscription.add(this.store.select(selectUser).subscribe((user) => {
      this.user = user;
      this.userFormGroup.patchValue({
        language: user.language,
        phone: user.phone,
      });
    }));
  }

  updateUserInformation(): void {
    if (this.userFormGroup.valid) {
      const updateUserRequest: UpdateAccountRequest = {
        ...this.userFormGroup.value,
        id: this.user.id,
      };
      this.store.dispatch(updateUser({user: updateUserRequest}));
    }
  }

  deleteAccount() {
    swal.fire({
      title: "Wait! Are you sure?",
      text: "This will delete all your applications along with your subscriptions. And your account will be permenantly deleted. This action is non-reversible.",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-primary"
      },
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: "Cancel",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {

        this.userService.deleteAccount(this.user.id).subscribe(_ => {
          swal.fire({
            title: 'Done!',
            text: 'Your account has been permenantly deleted.',
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary"
            }
          });

          this.store.dispatch(logout());
        })
      }
    });
  }

  unsubscribeFromPirogPro() {
    swal.fire({
      title: "Wait! Are you sure?",
      text: "This will suspend all your applications if you have more than two. And you won't take advantage of Pirog Pro anymore.",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-warning",
        cancelButton: "btn btn-primary"
      },
      confirmButtonText: 'Yes, unsubscribe!',
      cancelButtonText: "Cancel",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        this.userService.unsubscribeFromPirogPro().subscribe(_ => {
          swal.fire({
            title: 'We are sad to see you leave',
            text: 'You have successfully unsubscribed from Pirog Pro.',
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary"
            }
          }).then(() => {
            window.location.reload();
          });
        });
      }
    });
  }
  

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
