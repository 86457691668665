import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { CreateInputRequest } from 'src/app/models/create-input-request.model';
import { S3ServiceService } from 'src/app/services/s3-service.service';
import { loadSelectedApplicationIsConfigComplete } from 'src/app/store/repository/application/application-repository.actions';
import { environment } from 'src/environments/environment';
import { Application } from 'src/app/models/application.model';
import { TemplateService } from 'src/app/services/template.service';
import { TemplateRequest } from 'src/app/models/template-request.model';
import { interval, Subscription, switchMap } from 'rxjs';

@Component({
  selector: "app-inputs-table",
  templateUrl: "inputs-table.component.html",
  styleUrls: ['./inputs-table.component.scss']
})

export class InputsTableComponent implements OnInit {
  @Input() application: Application;
  @Input() templateRequests: ApplicationTemplateRequest[];
  @Input() selectedTemplateRequest: ApplicationTemplateRequest;
  @Input() categories: string[];
  public isCollapsed: boolean;

  bucketName: string;
  filesToUpload: { [requestName: string]: FileList; } = {};
  filesPaths: string[];
  fileNames: string[] = [];
  piFunctionBasePath: string;

  templateFg: UntypedFormGroup;

  colors: ['primary', 'primary-light-1', 'success', 'info', 'warning', 'danger'];
  categoryMappingColor = {};

  private statusSubscriptions: Subscription[] = [];

  @ViewChild('modalForm', { read: TemplateRef }) modalForm: TemplateRef<any>;
  @ViewChild('htmlEditorModal', { read: TemplateRef }) htmlEditorModal: TemplateRef<any>;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };
  htmlEditorSettings = {
    keyboard: true,
    class: "modal-xxl"
  };

  constructor(
    private templateService: TemplateService,
    private storageService: S3ServiceService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.bucketName = environment.OPS_BUCKET;
    this.initForm();
    this.piFunctionBasePath = `${environment.S3_PREFIXE}/${this.application?.domain.name}`
    this.storageService.basePath = this.piFunctionBasePath;
    this.startStatusTracking( "t4tr11");

  }

  private initForm(): void {
    this.templateFg = this.fb.group({
      value: [""]
    });
    this.filterTemplateRequests(this.categories);
  }

  private getData(): void {
    this.templateService
      .getTemplateRequests(this.application.id)
      .subscribe((response) => {
        this.templateRequests = response.filter(r => r.templateRequest.pageBuilder === false);

        this.filterTemplateRequests(this.categories);
        this.startStatusTracking( "t4tr11");


      });

  }
  private startStatusTracking( templateRequestId: string ) : void {
    this.templateRequests.forEach((request) => {
      if (request.templateRequest.id === templateRequestId) {
        const statusSubscription = interval(5000) // Toutes les 5 secondes
          .pipe(
            switchMap(() => 
              this.templateService.getInput(this.application.id, request.templateRequest.id)
            )
          )
          .subscribe({
            next: (updatedRequest) => {
  
              // Met à jour le statut de la demande spécifique
              request.status = updatedRequest.status;
  
              if (updatedRequest.status === 'COMPLETED' || updatedRequest. status === 'ERROR') {
                statusSubscription.unsubscribe(); // Arrêtez la souscription pour cet ID
              }
            },
            error: (error) => {
              console.error('Error updating status:', error);
            },
            complete: () => {
            },
          });
  
        this.statusSubscriptions.push(statusSubscription);
      }
    });
  }
  
  public isSubmitting = false;

  public createS3Link(filePathS3: any): string {
    if(!filePathS3.value.startsWith('http')){
      return environment.ID_CLOUDFRONT_CLIENT + '/' + filePathS3.value;
    }
    return filePathS3.value;
  }

  public async submitRequest() {
    if (this.templateFg.valid && !this.isSubmitting) {
      try {
        this.isSubmitting = true;
        var request: CreateInputRequest;

        if (['FILE'].includes(this.selectedTemplateRequest.templateRequest.type)) {
          const templateRequestName = this.selectedTemplateRequest.templateRequest.name;
          const files = this.filesToUpload[templateRequestName];

          await this.storageService.uploadFiles(files, this.filesPaths, this.bucketName);

          for (let i = 0; i < this.filesPaths.length; i++) {
            this.fileNames.push(this.filesPaths[i].split("/").pop())
          }
          request = {
            value: this.filesPaths.join(','),
            displayed: true,
            fileName: this.filesPaths.join(', ').split("/").pop(),
            applicationId: this.application.id,
            templateRequestId: this.selectedTemplateRequest.templateRequest.id
          };

        } else if (['TEXT', 'COLOR', 'TEXTAREA'].includes(this.selectedTemplateRequest.templateRequest.type)) {
          request = {
            value: this.templateFg.value.value ?? "",
            displayed: true,
            fileName: null,
            applicationId: this.application.id,
            templateRequestId: this.selectedTemplateRequest.templateRequest.id
          };
        }

        this.templateService.createInput(request).subscribe(
          (response) => {
            this.modalService.hide();
            this.templateFg.reset();
            this.getData();
            this.store.dispatch(loadSelectedApplicationIsConfigComplete({ idApplication: this.application.id }));
            this.startStatusTracking( "t4tr11");

          },
          (error) => {
            // Gérez les erreurs ici
          },
          () => {
            this.isSubmitting = false;
          }
        );
      } catch (error) {
        // Gérez les erreurs ici
      }
    }
  }

  onFileChange(event, requestName, isConsidered) {
    if (isConsidered) {
      if (event.target.files.length > 0) {
        this.filesPaths = [];
        var files = event.target.files;
        this.setFilesPaths(files, requestName);
        this.templateFg.patchValue({
          value: [this.filesPaths.join(", ")]
        });

        this.filesToUpload[requestName] = files;
      }
    }
  }

  openFormModal(templateRequest: ApplicationTemplateRequest) {
    this.selectedTemplateRequest = templateRequest;

    this.templateFg.patchValue({
      value: templateRequest.value
    });

    this.formModal = this.modalService.show(this.modalForm, this.form);
  }

  openHtmlEditor(templateRequest: ApplicationTemplateRequest) {
    this.selectedTemplateRequest = templateRequest;

    this.formModal = this.modalService.show(this.htmlEditorModal, this.htmlEditorSettings);
  }

  public filterTemplateRequests(categories) {
    // filter templateRequests given the categories
    this.templateRequests = this.templateRequests.filter(r => (r.templateRequest.pageBuilder === false) && (r.templateRequest.configurable)).filter((obj) => (categories.indexOf(obj.templateRequest.category) != -1));

  }

  private setFilesPaths(files: any[], templateRequestName: string) {
    for (let i = 0; i < files.length; i++) {
      this.filesPaths.push(this.piFunctionBasePath + `/temp/${templateRequestName}/${files[i].name}`);
    }
    //this.filesPaths = files.map(file => this.basePath + `/temp/${templateRequestName}/${file.name}`);
    //let path = `${this.application.domain.url}/src/assets/img/client/background/background.jpg`;
  }

  public setDisplayableValue(value, type) {
    if (!value) {
      return;
    }

    if (type === 'FILE') {
      let inputElements = value.split("/");
      return inputElements[inputElements.length - 1];
    }

    if (type === 'BOOLEAN') {
      return value === "true" ? "Oui" : "Non";
    }

    if (type === 'HTML') {
      return "Editer pour voir"
    }
    return value;
  }

  public booleanTemplateRequestChanged(value, templateRequest: any) {
    var isChecked = (<HTMLInputElement>value.target).checked;
    const request: CreateInputRequest = {
      value: isChecked.toString(),
      displayed: true,
      applicationId: this.application.id,
      templateRequestId: templateRequest.id
    };
    this.createInput(request);
  }

  submitTemplateRequestAsHtmlContent(content: string) {
    const request: CreateInputRequest = {
      value: content,
      displayed: true,
      applicationId: this.application.id,
      templateRequestId: this.selectedTemplateRequest.templateRequest.id
    };
    this.createInput(request);
  }

  private createInput(request: CreateInputRequest) {
    this.templateService.createInput(request).subscribe(
      (response) => {
        this.templateFg.reset();
        this.getData();
        this.store.dispatch(loadSelectedApplicationIsConfigComplete({ idApplication: this.application.id }));
      }
    );
  }
  getFormControl(controlName: string): FormControl {
    return this.templateFg.get(controlName) as FormControl;
  }
}