import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-page-builder',
  templateUrl: './page-builder.component.html',
  styleUrls: ['./page-builder.component.scss'],
})
export class PageBuilderComponent implements OnInit {
  @Input() application: Application;
  applicationUrl: string;
  @Input() templateRequests: ApplicationTemplateRequest[];
  @Output() templateRequestsChange = new EventEmitter<boolean>();

  @ViewChild('requestsModal', { read: TemplateRef }) requestsModal: TemplateRef<any>;

  formModal: BsModalRef;
  defaultModal: BsModalRef;
  selectTemplateModalSettings = {
    keyboard: true,
    class: 'modal-xl',
  };
  
  default = {
    keyboard: true,
    class: 'modal-xxl',
  };

  selectedSubCategory: string;

  constructor(
    private modalService: BsModalService,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.applicationUrl = this.application.domain.url;

  }

  // Fetch data from the server
  private getData(): void {
    if (!this.application?.id) return;

    this.templateService.getTemplateRequests(this.application.id).subscribe((response) => {
      this.templateRequests = response;
      this.templateRequestsChange.emit(true); 
    });
  }

  // Filter template requests by subcategory
  getTemplateRequests(subCategory: string): ApplicationTemplateRequest[] {
    console.log('Filtering template requests for subCategory:', subCategory);
    const filtered = this.templateRequests.filter(
      (a) => a.templateRequest.subCategory === subCategory && a.templateRequest.pageBuilder
    );
    console.log('Filtered Requests:', filtered);
    return filtered;
  }
  

  // Handle changes to the template requests
  onTemplateRequestsChange(change: boolean): void {
    if (change) {
      this.updateTemplateRequests();
    }
  }

  // Open a default modal
  openDefaultModal(modalDefault: TemplateRef<any>): void {
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  // Close the default modal
  closeDefaultModal(): void {
    if (this.defaultModal) {
      this.defaultModal.hide();
    }
  }

  // Update template requests and refresh data
  public updateTemplateRequests(): void {
    this.getData();
  }
}
