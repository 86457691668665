import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';
import { CreateInputRequest } from 'src/app/models/create-input-request.model';
import { S3ServiceService } from 'src/app/services/s3-service.service';
import { TemplateService } from 'src/app/services/template.service';
import { loadSelectedApplicationIsConfigComplete } from 'src/app/store/repository/application/application-repository.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template-requests-edit',
  templateUrl: './template-requests-edit.component.html',
  styleUrls: ['./template-requests-edit.component.scss']
})

export class TemplateRequestsEditComponent implements OnInit {
  @ViewChild('modalForm', { read: TemplateRef }) modalForm:TemplateRef<any>;
  @ViewChild('requestSelect', { read: TemplateRef }) requestSelect:TemplateRef<any>;

  @Input() appTemplateRequests: ApplicationTemplateRequest[];
  @Input() subCategory: string;
  @Input() application: Application;
  @Input() size: string = "sm";
  @Output() templateRequestsChange = new EventEmitter<boolean>();

  selectedTemplateRequest: ApplicationTemplateRequest;

  bucketName: string;
  filesToUpload: { [requestName: string] : FileList; } = {};
  filesPaths: string[];
  piFunctionBasePath : string;
  
  templateFg: UntypedFormGroup;

  colors: ['primary', 'primary-light-1', 'success', 'info', 'warning', 'danger'];
  categoryMappingColor= {};

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };
  selectTemplateModalSettings = {
    keyboard: true,
    class: "modal-xl"
  };
  

  @ViewChild('htmlEditorModal', { read: TemplateRef }) htmlEditorModal:TemplateRef<any>;

  htmlEditorSettings = {
    keyboard: true,
    class: "modal-xxl"
  };

  constructor(
    private templateService: TemplateService,
    private storageService: S3ServiceService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.bucketName = environment.OPS_BUCKET;
    this.initForm();
    this.piFunctionBasePath = `${environment.S3_PREFIXE}/${this.application.id}`
    this.storageService.basePath = this.piFunctionBasePath;
  }

  private initForm(): void {
    this.templateFg = this.fb.group({
      value: ["", Validators.required]
    });
  }

  public async submitRequest() {
    if (this.templateFg.valid) {
      let request: CreateInputRequest;
  
      if (this.selectedTemplateRequest.templateRequest.type === "FILE") {
        const templateRequestName = this.selectedTemplateRequest.templateRequest.name;
        const files = this.filesToUpload[templateRequestName];

        await this.storageService.uploadFiles(files, this.filesPaths, this.bucketName);

        request = {
          value: this.filesPaths.join(', '),
          displayed: true,
          applicationId: this.application.id,
          templateRequestId: this.selectedTemplateRequest.templateRequest.id
        };
      } else if (this.selectedTemplateRequest.templateRequest.type === "TEXT") {
        request = {
          value: this.templateFg.value.value ?? "",
          displayed: true,
          applicationId: this.application.id,
          templateRequestId: this.selectedTemplateRequest.templateRequest.id
        };
      }
      this.templateService.createInput(request).subscribe(() => {
        this.templateFg.reset(); 
        this.templateRequestsChange.emit(true);
        if (this.form) {
          this.modalService.hide(this.modalService.config.id);
        }
      });
    }
  }

  onFileChange(event, requestName, isConsidered) {
    if(isConsidered) {
      if (event.target.files.length > 0) {
        this.filesPaths = [];
        var files = event.target.files;
        this.setFilesPaths(files, requestName);
        this.templateFg.patchValue({
          value: [this.filesPaths.join(", ")]
        });

        this.filesToUpload[requestName] = files;
      }
    }
  }
  openFormModal(request: ApplicationTemplateRequest) {
    this.selectedTemplateRequest = request;
  
    this.templateFg.patchValue({
      value: request.value
    });
  
    this.formModal = this.modalService.show(this.modalForm, this.form);

}

  openRequestSelect() {
    this.formModal = this.modalService.show(this.requestSelect, this.selectTemplateModalSettings);

  }

  public createS3Link(filePathS3: any): string {
    if(!filePathS3.value.startsWith('http')){
      return environment.ID_CLOUDFRONT_CLIENT + '/' + filePathS3.value;
    }
    return filePathS3.value;
  }
  
  private setFilesPaths(files: any[], templateRequestName: string) {
    for(let i = 0; i < files.length; i++) {
      this.filesPaths.push(this.piFunctionBasePath + `/temp/${templateRequestName}/${files[i].name}`);
    }
  }

  public setDisplayed(id:string, value: string, displayed:boolean) {
  
    var request: CreateInputRequest;

    request = {
      value : value ? value : "",
      displayed: !displayed,
      applicationId : this.application.id,
      templateRequestId : id
    }
    
    this.templateService
    .createInput(request)
    .subscribe((response) => {        
      this.templateFg.reset();
      this.templateRequestsChange.emit(true);
    });

  }

  submitTemplateRequestAsHtmlContent(content: string) {
    const request: CreateInputRequest = {
      value: content,
      displayed: true,
      applicationId: this.application.id,
      templateRequestId: this.selectedTemplateRequest.templateRequest.id
    };

    this.createInput(request);
  }

  private createInput(request: CreateInputRequest) {
    this.templateService.createInput(request).subscribe(
      (response) => {
        this.templateFg.reset();
        this.templateRequestsChange.emit(true);
        this.store.dispatch(loadSelectedApplicationIsConfigComplete({idApplication: this.application.id}));
        this.modalService.hide(this.modalService.config.id);
      }
    );
  }

  openHtmlEditor(templateRequest: ApplicationTemplateRequest) {
    this.selectedTemplateRequest = templateRequest;

    this.formModal = this.modalService.show(this.htmlEditorModal, this.htmlEditorSettings);
  }

  getFormControl(controlName: string): FormControl {
    return this.templateFg.get(controlName) as FormControl;
  }

  public setDisplayableValue(value, type) {
    if(!value) {
      return;
    }

    if(type === 'FILE'){
      let inputElements = value.split("/");
      return inputElements[inputElements.length-1];
    }
    if(type === 'BOOLEAN'){
      return value === "true" ? "Oui" : "Non";
    }
    if(type === 'HTML'){
      return "Editer pour voir"
    }
    return value;
  }
  
}
