<div class="modal-body p-0">
  <div class="card bg-white border-0 mb-0">
    <div class="card-body">
      <div class="text-center mb-4">
        <p><strong>{{'itc.h8'|translate}}</strong></p>
      </div>

      <table class="table table-white align-items-center table-flush table-striped">
        <thead class="thead-light">
          <tr>
            <th></th>
            <th>{{'itc.h1'|translate}}</th>
            <th>{{'itc.h2'|translate}}</th>
            <th>{{'itc.h3'|translate}}</th>
            <th>{{'itc.h4'|translate}}s</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let request of appTemplateRequests">
            <td class="table-position">
              <span class="badge badge-pill text-white"
                [ngStyle]="{'background-color': request.templateRequest.color}">{{ request.templateRequest.subCategory
                }}</span>

            </td>

            <td class="table-user">
              <b> {{ 'template_request.' + request?.templateRequest.descriptionKey + '.description'|translate }}</b>
            </td>

            <td>
              <app-input-status [status]="request.status"></app-input-status>
            </td>

            <td>
              <p class="font-weight-bold line-break text-center" href="javascript:void(0)">
                <small>{{ setDisplayableValue(request.value, request.templateRequest.type) }}</small>
              </p>
            </td>

            <td class="table-actions text-primary">
              <a
                *ngIf="request.templateRequest.type === 'TEXT' || request.templateRequest.type === 'FILE'" class="table-action mx-1"
                [tooltip]="request.templateRequest.type === 'FILE' ? ('itc.upload'|translate) : ('itc.edit'|translate) "
                placement="top" (click)="openFormModal(request)">
                <i [ngClass]="request.templateRequest.type === 'FILE' ? 'fas fa-upload' : 'fas fa-edit' "> </i>
              </a>
              <a
                *ngIf="request.templateRequest.type === 'HTML'"
                class="table-action"
                [tooltip]="('itc.edit'|translate)"
                placement="top"
                (click)="openHtmlEditor(request)">
                <i class="fas fa-edit"> </i>
              </a>

              <a
                *ngIf="request.status == 'COMPLETED' && request.templateRequest.type === 'FILE'"
                [tooltip]="('itc.open'|translate)"
                placement="top"
                class="table-action text-primary" [attr.href]="createS3Link(request)" target="_blank">
                <i class="fas fa-external-link-alt"></i>
              </a>

              <span *ngIf="request.templateRequest.type != 'FILE'" class="input-group-append d-inline mx-1"
                [tooltip]="request.displayed ? 'Visible' : 'Hidden'">
                <span class="text-primary" type="button"
                  (click)="setDisplayed(request.templateRequest.id, request.value, request.displayed)">
                  <i *ngIf="request.displayed" class="fa fa-eye"></i>
                  <i *ngIf="!request.displayed" class="fa fa-eye-slash text-warning"></i>
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #modalForm bsModal id="modalForm">
  <div class="modal-body p-0">
    <div class="card bg-light border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">
        <div class="text-center mb-4">
          <p>
            {{ 'template_request.' + selectedTemplateRequest?.templateRequest?.descriptionKey + '.action'| translate }}
        </div>

        <form [formGroup]="templateFg" (ngSubmit)="submitRequest()">
          <div class="py-0 px-10  mb-3">
            <div class="input-group input-group-alternative">
              <input *ngIf="selectedTemplateRequest?.templateRequest?.type === 'TEXT'" class="form-control"
                placeholder="{{setDisplayableValue(selectedTemplateRequest.value, selectedTemplateRequest.templateRequest.type)}}"
                [formControl]="getFormControl('value')" type="text" name="value"/>
              <input *ngIf="selectedTemplateRequest?.templateRequest?.type === 'FILE'" class="form-control"
                placeholder="{{setDisplayableValue(selectedTemplateRequest.value, selectedTemplateRequest.templateRequest.type)}}"
                type="file" name="value" multiple="multiple"
                (change)="onFileChange($event, selectedTemplateRequest?.templateRequest?.name, selectedTemplateRequest?.templateRequest?.type === 'FILE')"
                />
              <!-- <app-input-dropzone
                *ngIf="selectedTemplateRequest?.templateRequest?.type === 'FILE'"

              ></app-input-dropzone> -->
            </div>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-primary my-4" (click)="$event.stopPropagation()">
              {{'itc.h7'|translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #htmlEditorModal>
  <div class="modal-body p-0">
    <div class="card bg-light border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">
        <pi-html-editor [initialContent]="selectedTemplateRequest.value"
          (submitHtmlOutput)="submitTemplateRequestAsHtmlContent($event)"></pi-html-editor>
      </div>
    </div>
  </div>
</ng-template>
