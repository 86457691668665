import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';

@Component({
  selector: 'app-pi-function',
  templateUrl: './pi-function.component.html',
  styleUrls: ['./pi-function.component.scss'],
})
export class PiFunctionComponent implements OnInit {
  @Output() templateRequestsChange = new EventEmitter<boolean>();

  @Input() application: Application;
  @Input() templateRequests: ApplicationTemplateRequest[];
  @Input() getTemplateRequests: (subCategory: string) => ApplicationTemplateRequest[];
  @Input() updateTemplateRequests: () => void;
  selectedSubCategory: string;
  @ViewChild('requestsModal', { read: TemplateRef }) requestsModal: TemplateRef<any>;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };
  selectTemplateModalSettings = {
    keyboard: true,
    class: "modal-xl"
  };

  constructor(private modalService: BsModalService) { }


  ngOnInit(): void { }

  handleTemplateRequestsChange(event: boolean): void {
    this.templateRequestsChange.emit(event); 
  }
  openRequestsModal(subCategory: string) {
    this.selectedSubCategory = subCategory;
    this.formModal = this.modalService.show(this.requestsModal, this.selectTemplateModalSettings);
  }
}
