<head>
  <link
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    rel="stylesheet"
  />
  <!-- local case -->
  <link
    href="https://dev.env.pirog.io/t1/assets/scss/styles.css"
    rel="stylesheet"
  />
  <!-- dev & prd -->
  <link href="/t1/assets/scss/styles.css" rel="stylesheet" />
</head>

<div class="global-container">
  <div class="row h-100 w-100 mx-0">
    <div
      class="col-lg-8 col-md-12 px-0 primary-template-request-link"
      (click)="openRequestsModal('page')"
    >
      <a target="_blank">
        <img
          id="image"
          src="assets/templates/piFunction/t1_backgroundImage.png"
          alt="Background Image"
        />
      </a>
    </div>
    <div class="col-lg-4 col-md-12 col-xs-12">
      <div class="h-100">
        <div class="container px-2 mx-auto">
          <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
            <div
              class="primary-template-request-link"
              (click)="openRequestsModal('presentation')"
            >
              <a target="_blank">
                <img src="assets/img/logo/logo_color.png" class="logo mt-3" />
              </a>

              <span class="login100-form-title mt-3">
                Titre de l'application
              </span>
            </div>

            <div
              class="mt-social primary-template-request-link"
              (click)="openRequestsModal('social-medias')"
            >
              <div class="txt1 text-center p-t-54 p-b-20">
                <span> Suivez-nous sur nos réseaux sociaux </span>
              </div>
              <div class="col-md-12 text-center">
                <ul class="social-network social-circle">
                  <a target="_blank" class="btn-social btn-facebook"
                    ><i class="fa-brands fa-facebook-f fa-lg"></i
                  ></a>
                  <a target="_blank" class="btn-social btn-linkedin"
                    ><i class="fa-brands fa-linkedin-in fa-lg"></i
                  ></a>
                  <a target="_blank" class="btn-social btn-twitter"
                    ><i class="fa-brands fa-twitter fa-lg"></i
                  ></a>
                  <a target="_blank" class="btn-social btn-instagram"
                    ><i class="fa-brands fa-instagram fa-lg"></i
                  ></a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #requestsModal>
  <app-template-requests-edit
    class="w-100"
    [appTemplateRequests]="getTemplateRequests(selectedSubCategory)"
    [subCategory]="'req.background' | translate"
    [application]="application"
    [size]="'md'"
    (templateRequestsChange)="handleTemplateRequestsChange($event)"
  >
  </app-template-requests-edit>
</ng-template>
