import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';

@Component({
  selector: 'app-pi-profile',
  templateUrl: './pi-profile.component.html',
  styleUrls: ['./pi-profile.component.scss']
})
export class PiProfileComponent implements OnInit {
  @Output() templateRequestsChange = new EventEmitter<boolean>();

  @Input() application: Application;
  @Input() templateRequests: ApplicationTemplateRequest[];
  @Input() getTemplateRequests: (subCategory: string) => ApplicationTemplateRequest[];
  @Input() updateTemplateRequests: () => void;

  completName:string="Nom Prenom";
  job:string="Métier";
  @ViewChild('requestsModal', { read: TemplateRef }) requestsModal:TemplateRef<any>;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };
  selectTemplateModalSettings = {
    keyboard: true,
    class: "modal-xl"
  };

  openRequestModalSubject: Subject<void> = new Subject<void>();
  selectedTemplateRequests: ApplicationTemplateRequest[];

  selectedSubCategory: string = "";

  constructor(public translateService: TranslateService,private modalService: BsModalService) { }

  handleTemplateRequestsChange(event: boolean ): void {
   
    this.templateRequestsChange.emit(event); 
  }
  async ngOnInit(): Promise<void> {
    const defaultName=  await this.translateService.get('t2.admin.page_builder.name').toPromise().then();
    const defaultJob=  await this.translateService.get('t2.admin.page_builder.job').toPromise().then();
    const completNameValue = this.templateRequests
    .filter(a => a.templateRequest.name === "completeName")
    .map(a => a.value);
    this.completName=completNameValue[0] || defaultName
    const jobValue = this.templateRequests
    .filter(a => a.templateRequest.name === "job")
    .map(a => a.value);
    this.job=jobValue[0] || defaultJob

  }
  openRequestsModal(subCategory: string) {
    this.selectedSubCategory = subCategory;
    this.formModal = this.modalService.show(this.requestsModal, this.selectTemplateModalSettings);
  }

}
