import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ElementDTO, UserFileRequest, RenameRequest, UserFile, UserFileResponse } from '../models/user-file.model';
import { ShareRequest } from '../models/share-request.model';
import { ShareableLink } from '../models/shareablel-link.model';

@Injectable({
    providedIn: 'root',
})
export class TemplateT3Service {
    prefixe = 'templates/t3';
    constructor(private httpClient: HttpClient) {}

    // userfiles
    public createUserFile(userFile: UserFile) {
        return this.httpClient.post<UserFileResponse>(this.prefixe + `/userfiles`,userFile);
    }

    public getUserFileById(id: String) {
        return this.httpClient.get<UserFileResponse[]>(this.prefixe + `/userfiles/${id}`);
    }

    public updateUserfileById(id: String, userFile: UserFile) {
        return this.httpClient.put<UserFileResponse>(this.prefixe + `/userfiles/${id}`, userFile);
    }

    public deleteUserFileById(id: String) {
        return this.httpClient.delete(this.prefixe + `/userfiles/${id}`);
    }
    
    public createUserFiles(userFiles: UserFile[]) {
        return this.httpClient.post<UserFileResponse[]>(this.prefixe + `/userfiles/bulk`, userFiles);
    }


    public deleteUserFiles(userFileRequest: UserFileRequest, applicationId: string, includeChildren: boolean, isPublic: boolean) {
        let params = new HttpParams();
        params = params.set('applicationId', applicationId)
        params = params.set('includeChildren', includeChildren)
        if(isPublic!=null){params = params.set('isPublic', isPublic)}
        
        const options = {
            headers: new HttpHeaders({'Content-Type': 'application/json'}), 
            body: userFileRequest,
            params: params
        };

        return this.httpClient.delete(this.prefixe + `/userfiles`, options);
    }

    public renameUserFiles(renameRequest: RenameRequest) {
        return this.httpClient.put<UserFileResponse[]>(this.prefixe + `/userfiles/rename`, renameRequest);
    }

    public upsertUserFiles(userFiles: UserFile[]) {
        return this.httpClient.put<UserFileResponse[]>(this.prefixe + `/userfiles/bulk`, userFiles);
    }

    // elements

    public getElement(userFileRequest: UserFileRequest, applicationId: string) {
        let params = new HttpParams();
        params = params.set('applicationId', applicationId)
        return this.httpClient.post<ElementDTO>(this.prefixe + `/elements`, userFileRequest, {params});
    }

    // verifyEmailForFileSharing

    // getUserFilesPublic

 /// 

    // public getUserFiles(userFileEntry: UserFileEntry, withChildrens: boolean) {
    //     let params = new HttpParams();
    //     params = params.set('withChildrens', withChildrens)
    //     return this.httpClient.post<UserFilesEntry>(this.prefixe + `/userfiles/search`, userFileEntry, {params});
    // }

    public deleteUserFile(id: String) {
        return this.httpClient.delete(this.prefixe + `/userfiles/${id}`);
    }
    
    public generateShareableLink(request: ShareRequest) {
        return this.httpClient.post<ShareableLink>(this.prefixe + '/share', request);
    }
    
      

}
